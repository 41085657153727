import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import caretRightIcon from '../../images/library/caretRight.svg';
import { LibraryPost } from '../../types';
import LibraryCreatorName from './LibraryCreatorName';
import LibraryCreatorPicture from './LibraryCreatorPicture';
import styles from './LibraryPostsList.module.scss';

interface LibraryPostsListProps {
  posts: Pick<LibraryPost, 'path' | 'creator' | 'title'>[];
  card?: boolean;
}

const LibraryPostsList: React.SFC<LibraryPostsListProps> = ({
  posts,
  card = false,
}) => (
  <>
    {posts.map((post) => (
      <Link
        key={post.path}
        to={post.path}
        className={classNames(styles.post, {
          [styles.card]: card,
        })}
      >
        <div className={styles.picture}>
          <LibraryCreatorPicture {...post.creator} />
        </div>
        <div className={styles.info}>
          <LibraryCreatorName {...post.creator} />
          <div className={styles.title}>{post.title}</div>
        </div>
        {!card && (
          <div className={styles.caret}>
            <img src={caretRightIcon} alt="" role="presentation" />
          </div>
        )}
      </Link>
    ))}
  </>
);

export default LibraryPostsList;
