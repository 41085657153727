import { Link } from 'gatsby';
import React from 'react';
import caretRightIcon from '../../images/library/caretRight.svg';
import styles from './LibraryBreadcrumbs.module.scss';

interface LibraryPostPageProps {
  category?: { name: string; url: string };
  collection?: { name: string; url: string };
}

const Caret = () => (
  <img
    className={styles.caret}
    src={caretRightIcon}
    alt=""
    role="presentation"
  />
);

const LibraryBreadcrumbs = ({ category, collection }: LibraryPostPageProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.breadcrumb}>
        <Link to="/library/">Library</Link>
        <span className={styles.nowrap}>
          &#65279;
          <Caret />
        </span>
      </div>
      {!!category && (
        <div className={styles.breadcrumb}>
          <Link to={category.url}>{category.name}</Link>
          <span className={styles.nowrap}>
            &#65279;
            <Caret />
          </span>
        </div>
      )}
      {!!collection && (
        <div className={styles.breadcrumb}>
          <Link to={collection.url}>{collection.name}</Link>
          <span className={styles.nowrap}>
            &#65279;
            <Caret />
          </span>
        </div>
      )}
    </div>
  );
};

export default LibraryBreadcrumbs;
