import React from 'react';
import { LibraryCategory, LibraryCollection } from '../../types';
import LibraryGridCollection from './LibraryGridCollection';

interface LibraryCollectionsListProps {
  collections: LibraryCollection[];
  color: LibraryCategory['color'];
}

const LibraryCollectionsList: React.SFC<LibraryCollectionsListProps> = ({
  collections,
  color,
}) => (
  <>
    {collections.map((collection) => (
      <LibraryGridCollection
        key={collection.slug}
        collection={collection}
        color={color}
        horizontal
      />
    ))}
  </>
);

export default LibraryCollectionsList;
