import { graphql } from 'gatsby';
import React from 'react';
import MarketingLayout from '../components/layout/MarketingLayout';
import UntitledStyle from '../components/layout/UntitledStyle';
import LibraryCategoryOrCollectionPage from '../components/library/LibraryCategoryOrCollectionPage';
import LibraryWithCategoriesLayout from '../components/library/LibraryWithCategoriesLayout';
import { LibraryCategoriesYaml } from '../graphql-types';
import { toLibraryCategory } from '../utils/toLibraryType';

interface LibraryCategoryTemplateProps {
  data: {
    category: LibraryCategoriesYaml;
  };
}

const LibraryCategoryTemplate: React.SFC<LibraryCategoryTemplateProps> = ({
  data: { category },
}) => {
  const categoryForPage = toLibraryCategory(category);

  return (
    <MarketingLayout metaTitle={`${categoryForPage.name} - Slab Library`}>
      <UntitledStyle>
        <LibraryWithCategoriesLayout>
          <LibraryCategoryOrCollectionPage
            category={categoryForPage}
            collection={undefined}
          />
        </LibraryWithCategoriesLayout>
      </UntitledStyle>
    </MarketingLayout>
  );
};

export const query = graphql`
  query LibraryCategoryOrCollectionPageQuery($slug: String!) {
    category: libraryCategoriesYaml(fields: { slug: { eq: $slug } }) {
      ...LibraryCategoryFragment
      collections {
        name
        description
        slug
        posts {
          ...LibraryPostFragment
        }
      }
    }
  }
`;

export default LibraryCategoryTemplate;
